import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store";
import axios from "axios";

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
  },

  /*본부소개*/
  {
    path: '/about/message',
    name: 'message',
    component: () => import('@/views/about/message.vue')
  },
  {
    path: '/about/vision',
    name: 'vision',
    component: () => import('@/views/about/vision.vue')
  },
  {
    path: '/about/logo',
    name: 'logo',
    component: () => import('@/views/about/logo.vue')
  },
  {
    path: '/about/history',
    name: 'history',
    component: () => import('@/views/about/history.vue')
  },
  {
    path: '/about/about',
    name: 'about',
    component: () => import('@/views/about/about.vue')
  },
  {
    path: '/about/organization',
    name: 'organization',
    component: () => import('@/views/about/organization.vue')
  },
  {
    path: '/about/regulation',
    name: 'regulation',
    component: () => import('@/views/about/regulation.vue')
  },
  {
    path: '/about/location',
    name: 'location',
    component: () => import('@/views/about/location.vue')
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import('@/views/AboutView.vue')
  // },

  /*조직*/
  {
    path: '/organization/central',
    name: 'central',
    component: () => import('@/views/organization/central.vue')
  },
  {
    path: '/organization/regional',
    name: 'regional',
    component: () => import('@/views/organization/regional.vue')
  },
  {
    path: '/organization/institute',
    name: 'institute',
    component: () => import('@/views/organization/institute.vue')
  },
  {
    path: '/organization/ministry',
    name: 'org_ministry',
    component: () => import('@/views/organization/ministry.vue')
  },
  {
    path: '/organization/former',
    name: 'former',
    component: () => import('@/views/organization/former.vue')
  },
  {
    path: '/organization/office',
    name: 'former',
    component: () => import('@/views/organization/office.vue')
  },

  /*사역*/
  {
    path: '/ministry/reformed',
    name: 'reformed',
    component: () => import('@/views/ministry/reformed.vue')
  },
  {
    path: '/ministry/ministry',
    name: 'ministry',
    component: () => import('@/views/ministry/ministry.vue')
  },
  {
    path: '/ministry/ministry1',
    name: 'ministry1',
    component: () => import('@/views/ministry/ministry1.vue')
  },
  {
    path: '/ministry/ministry2',
    name: 'ministry2',
    component: () => import('@/views/ministry/ministry2.vue')
  },
  {
    path: '/ministry/ministry3',
    name: 'ministry3',
    component: () => import('@/views/ministry/ministry3.vue')
  },
  {
    path: '/ministry/education2',
    name: 'education2',
    component: () => import('@/views/ministry/education2.vue')
  },
  {
    path: '/ministry/education1',
    name: 'education1',
    component: () => import('@/views/ministry/education1.vue')
  },
  {
    path: '/ministry/education',
    name: 'education',
    component: () => import('@/views/ministry/education.vue')
  },
  {
    path: '/ministry/smarlog',
    name: 'smarlog',
    component: () => import('@/views/ministry/smarlog.vue')
  },
  {
    path: '/ministry/smarlog1',
    name: 'smarlog1',
    component: () => import('@/views/ministry/smarlog1.vue')
  },
  {
    path: '/ministry/smarlog2',
    name: 'smarlog2',
    component: () => import('@/views/ministry/smarlog2.vue')
  },
  {
    path: '/ministry/smarlog3',
    name: 'smarlog3',
    component: () => import('@/views/ministry/smarlog3.vue')
  },
  {
    path: '/ministry/nine-connection',
    name: 'nine-connection',
    component: () => import('@/views/ministry/nine-connection.vue')
  },
  {
    path: '/ministry/nine-connection1',
    name: 'nine-connection1',
    component: () => import('@/views/ministry/nine-connection1.vue')
  },
  {
    path: '/ministry/nine-connection2',
    name: 'nine-connection2',
    component: () => import('@/views/ministry/nine-connection2.vue')
  },
  {
    path: '/ministry/nine-connection3',
    name: 'nine-connection3',
    component: () => import('@/views/ministry/nine-connection3.vue')
  },
  {
    path: '/ministry/nine-connection4',
    name: 'nine-connection4',
    component: () => import('@/views/ministry/nine-connection4.vue')
  },
  {
    path: '/ministry/nine-connection5',
    name: 'nine-connection5',
    component: () => import('@/views/ministry/nine-connection5.vue')
  },
    /*후원*/
  {
    path: '/support/support',
    name: 'support',
    component: () => import('@/views/support/support.vue')
  },

  // 로그인
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/signup.vue')
  },
  // 로그아웃
  {
    path: '/logout',
    name: 'logout',
    component: {
      beforeRouteEnter (to, from, next) {
        store.commit('logout');
        next({ path: '/' });
        return false;
      }
    }
  },
  {   // 회원가입 완료 페이지
    path: '/welcome',
    name: 'welcome',
    component: () => import('@/views/welcome.vue'),
    // meta: { requiresAuth: true },
  },

  // 게시판 관련 링크
  { // 글 목록
    path: '/list/:board_id',
    name: 'board_list',
    component: () => import('@/views/BoardList.vue'),
  },
  { // 글 조회
    path: '/view/:board_id/:post_id',
    name: 'board_view',
    component: () => import('@/views/BoardView.vue'),
  },
  { //글 쓰기
    path: '/write/:board_id',
    name: 'board_write',
    component: () => import('@/views/BoardWrite.vue'),
  },
  { //글 쓰기
    path: '/modify/:board_id/:post_id',
    name: 'board_modify',
    component: () => import('@/views/BoardWrite.vue'),
  },


  /* 관리자 */
  {
    path: "/admin",
    // redirect: "/admin/dashboard",
    component: () => import('@/layouts/admin.vue'),
    children: [
      {
        // 관리자 메인
        path: '/adm/main',
        name: 'admin_main',
        component: () => import('@/views/admin/main.vue'),
        meta: { requiresAuth: true },
      },
      {
        // 관리자 회원관리
        path: '/adm/member',
        name: 'admin_member_list',
        component: () => import('@/views/admin/member.vue'),
        meta: { requiresAuth: true },
      },
      {
        // 관리자 회원관리
        path: '/adm/board',
        name: 'admin_board',
        component: () => import('@/views/admin/board.vue'),
        meta: { requiresAuth: true },
      },
      {
        // 관리자 회원관리
        path: '/adm/popup',
        name: 'admin_popup_list',
        component: () => import('@/views/admin/popup.vue'),
        meta: { requiresAuth: true },
      },
      {
        // 공지사항
        path: '/adm/board_list/:board_id',
        name: 'admin_board_list',
        component: () => import('@/views/admin/board_list.vue'),
        meta: { requiresAuth: true },
      },
      {
        // 공지사항
        path: '/adm/board_view/:board_id/:post_id',
        name: 'admin_board_view',
        component: () => import('@/views/admin/board_view.vue'),
        meta: { requiresAuth: true },
      },
      {
        // 공지사항
        path: '/adm/board_write/:board_id',
        name: 'admin_board_write',
        component: () => import('@/views/admin/board_write.vue'),
        meta: { requiresAuth: true },
      },
      {
        // 공지사항
        path: '/adm/board_modify/:board_id/:post_id',
        name: 'admin_board_modify',
        component: () => import('@/views/admin/board_write.vue'),
        meta: { requiresAuth: true },
      },
    ]
  }


]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

/*const app = createApp({})
app.use(router)

app.mount('#app')*/

router.beforeEach((to, from, next) => {
  // TODO: 로그인 여부 확인
  // 라우터 가드
  if( to.matched.some( record => record.meta.requiresAuth ) ){
    const url = "/api/member.php"
    const formData = new FormData;
    formData.append('mode', 'checkAuth');
    try {
      axios.post(url,formData).then(function(res) {
        if( res.data.err === 0 ){
          store.commit('login', {
            access_token: res.data.access_token,
            user_info: res.data.user_info,
          });
          localStorage.setItem('access_token', res.data.access_token);
          localStorage.setItem('isAuthenticated', true);

          store.dispatch('getMyInfo');

          next();
        }
        else {
          alert('로그인 후 이용 가능 합니다.');
          next({ name: 'login' });
        }
      })
    } catch (e) {
      alert('로그인 후 이용 가능 합니다.');
      next({ name: 'login' });
    }
  }
  else {
    // store.dispatch('getMyInfo').then(r => (console.log(r)));
    if( to.name !== 'logout' ){
      const token = localStorage.getItem('access_token');
      if( token ){
        store.dispatch('getMyInfo');
      }
    }
    next();
  }
})


export default router