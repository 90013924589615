import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import '@/assets/css/index.css'
import '@/assets/css/reset.css'
import '@/assets/css/board.css'

//vuetensil
import { VDrawer, VToggle } from 'vuetensils/src/components';

axios.defaults.paramsSerializer = function (paramObj) {
    const params = new URLSearchParams()
    for (const key in paramObj) {
        params.append(key, paramObj[key])
    }

    return params.toString()
}

axios.defaults.timeout = 100000;
axios.defaults.withCredentials = true;
axios.defaults.baseURL = "http://" + window.location.host;
axios.defaults.port = ":" + window.location.port;
// 캐싱 방지
axios.defaults.headers.get['Cache-Control'] = 'no-cache';
axios.defaults.headers.get['Pragma'] = 'no-cache';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Headers'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = "GET, POST, PUT, DELETE, OPTIONS"
axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true;

axios.interceptors.request.use((request) => {
    if (request.port != ":8080") {
        if (request.url.indexOf("/api/") > -1) {
            // request.baseURL = "http://nextgen.dbconn.kr/";
            request.baseURL = "http://"+window.location.host+"/";
            // console.log(request.baseURL);
            // request.url = request.url.replace("/back", "/api");
        }
    }
    const access_token = localStorage.getItem('access_token');
    if( access_token ){
        request.headers.authorization = access_token;
    }
    // request.headers.authorization = "Bearer " + localStorage.getItem('authorization');
    return request;
})

const app = createApp(App)
app.config.globalProperties.axios = axios;
app.config.globalProperties.$store = store;
app.use(router).use(store).mount('#app')

app.component('VDrawer', VDrawer)
app.component('VToggle', VToggle)